<div>
  <div class="row mt-3">
    <div class="col-lg-8 col-md-6 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchFilter" placeholder="Suche">
      </mat-form-field>
    </div>
    <div class="col-lg-4 col-md-6 col-12">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Gruppenfilter</mat-label>
        <mat-select multiple (selectionChange)="applyGroupFilter($event)" [(ngModel)]="groupsFilter">
          <mat-option *ngFor="let group of groupList" [value]="group.id">{{group.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" matSortActive="date"
      matSortDirection="asc" matSortDisableClear>
      <!-- Name Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 20vw; max-width: 30vw;">Name</th>
        <td mat-cell *matCellDef="let element" [routerLink]="['../view/', element.id]"
          style="min-width: 20vw; max-width: 30vw;">
          {{element.title}}</td>
      </ng-container>

      <!-- Datum Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 100px;">Datum</th>
        <td mat-cell *matCellDef="let element" [routerLink]="['../view/', element.id]" style="min-width: 100px;">
          <div>
            {{ getDate(element.startDate) }}
          </div>
          <div *ngIf="!element.allDay" style="margin-left: 17px;">
            {{ element.startTime }} Uhr
          </div>
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="max-width: 12vw;">Ort</th>
        <td mat-cell *matCellDef="let element" [routerLink]="['../view/', element.id]" style="max-width: 12vw;">
          <div *ngIf="element.type === 'local'">
            <mat-icon style="margin-left: 10px;" matTooltip="Präsenz" matTooltipPosition="above">business</mat-icon>
            <span
              [hidden]="element.mapsUrl !== 'https://www.google.com/maps/search/?api=1&query=85521%20Ottobrunn%20Ottostraße%2019'"
              style="margin-left: 10px;">
              <mat-icon matTooltip="im Gerätehaus" matTooltipPosition="above">
                home
              </mat-icon>
            </span>
          </div>
          <div *ngIf="element.type === 'hybrid'">
            <mat-icon style="margin-left: 10px;" matTooltip="Hybrid" matTooltipPosition="above">maps_home_work
            </mat-icon>
            <span
              [hidden]="element.mapsUrl !== 'https://www.google.com/maps/search/?api=1&query=85521%20Ottobrunn%20Ottostraße%2019'"
              style="margin-left: 10px;">
              <mat-icon matTooltip="im Gerätehaus" matTooltipPosition="above">
                home
              </mat-icon>
            </span>
          </div>
          <div *ngIf="element.type === 'online'">
            <mat-icon style="margin-left: 10px;" matTooltip="online" matTooltipPosition="above">language</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="response">
        <th mat-header-cell mat-sort-header *matHeaderCellDef matTooltip="Rückmeldung" matTooltipPosition="above">RM
        </th>
        <td mat-cell *matCellDef="let element" (click)="openFeedback(element, $event)">
          <mat-icon *ngIf="element.response === 1" color="warn" style="margin-left: 15%" matTooltip="Abgesagt"
            matTooltipPosition="above">clear
          </mat-icon>
          <mat-icon *ngIf="element.response === 2" color="success" style="margin-left: 15%" matTooltip="Zugesagt"
            matTooltipPosition="above">done
          </mat-icon>
          <mat-icon *ngIf="element.response === 0 && !element.title.includes('ausgebucht')" color="primary"
            style="margin-left: 15%" matTooltip="Keine Rückmeldung abgegeben" matTooltipPosition="above">help
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <span [hidden]="element.type === 'local' || element.meetingUrl === null" style="margin-right: 15px;"
            *ngIf="element.status !== 'abgeschlossen'">
            <a target="_blank" href="{{element.meetingUrl}}">
              <mat-icon matTooltip="Termin öffnen" matTooltipPosition="above">open_in_browser
              </mat-icon>
            </a>
          </span>
          <span [hidden]="!(element.status === 'angelegt' && _login.hasPermission('admin-event-check'))">
            <mat-icon matTooltip="Termin in Prüfung" matTooltipPosition="above">preview
            </mat-icon>
          </span>
          <span *ngIf="element.status === 'abgeschlossen'">
            <mat-icon matTooltip="Termin abgeschlossen" matTooltipPosition="above">check_circle
            </mat-icon>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <span [hidden]="element.orga === 'foundation'" style="margin-right: 15px;">
            <mat-icon matTooltip="Technikveranstaltung" matTooltipPosition="above">
              local_fire_department
            </mat-icon>
          </span>
          <span [hidden]="element.orga === 'technic' " style="margin-right: 15px;">
            <mat-icon matTooltip="Vereinsveranstaltung" matTooltipPosition="above">
              foundation
            </mat-icon>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="visibility">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <span [hidden]="element.visibility === 0" style="margin-right: 15px;">
            <mat-icon matTooltip="sichtbar für alle" matTooltipPosition="above">
              visibility
            </mat-icon>
          </span>
          <span [hidden]="element.visibility === 1" style="margin-right: 15px;">
            <mat-icon matTooltip="sichtbar für Teilnehmer / Führung" matTooltipPosition="above">
              lock
            </mat-icon>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<div class="mt-4 row" *ngIf="showLoader">
  <div class="col-6">
    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
  </div>
  <div class="col-6">
    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
  </div>
  <div class="col-12">
    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
  </div>
</div>
<mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="pageIndex"
  (page)="applyPagination($event)" showFirstLastButtons id="paginator" [hidden]="showLoader"></mat-paginator>