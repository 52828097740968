import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Courses } from '@ffo/mitgliederbereich-types';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { CourseCategory, UserNewCoursedataDialogComponent } from 'src/app/util/dialogs/user-new-coursedata-dialog/user-new-coursedata-dialog.component';
import { Login } from 'src/app/util/login';

interface Record {
  id: number;
  icon?: string | null;
  name: string;
  date: string;
  background?: string;
  fileRef?: string;
}

interface CourseExtend extends Courses {
  category: string;
}

@Component({
  selector: 'app-profile-timeline',
  templateUrl: './profile-timeline.component.html',
  styleUrls: ['./profile-timeline.component.scss']
})
export class ProfileTimelineComponent implements OnInit {

  dayjs = dayjs;
  userID = null;
  baseURL = getHostUrl();
  isPWA = false;
  showLoader = true;

  searchField = new FormControl();
  courseList: CourseCategory[] = [];

  @ViewChild('menu')
  menu: any;

  constructor(private _http: HttpClient,
    public _login: Login,
    public _bottomSheet: MatBottomSheet,
    private _config: ConfigModule) { }

  ngOnInit(): void {
    this._config.setTitle('Laufbahnereignisse');
    this.loadData();
    this.userID = this._login.getUserID();
    this.isPWA = window.matchMedia('(display-mode: standalone)').matches;
    this.loadCourses();
  }

  ngAfterViewInit() {
    this.searchField.valueChanges.subscribe(result => {
      this.filterResults(result);
    })
  }

  filterResults(value) {
    this.items = this.rawItems.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
  }

  async loadCourses() {
    try {
      const courses = await this._http.get<CourseExtend[]>(`${getHostUrl()}datainfo/courses`).toPromise();
      this.courseList = this.buildData(courses);      
    } catch (e) {
      return false;
    }
    return true;
  }

  buildData(data: CourseExtend[]) {
    const categories = this.buildCategory(data);
    categories.forEach(elem => {
      elem.data = data.filter(s => s.category == elem.name);
    });
    return categories;
  }

  buildCategory(data: CourseExtend[]) {
    const categories: CourseCategory[] = [];
    data.forEach(elem => {
      if (!categories.find(c => c.name == elem.category))
        categories.push({
          name: elem.category,
          data: [],
        });
    });
    return categories;
  }

  loadData() {
    this._http.get<any>(`${getHostUrl()}profile/timeline`).subscribe(result => {
      this.items = result.map(item => {
        item.background = this.getRandomColor();
        return item;
      });
      this.rawItems = this.items;

      setTimeout(() => {
        this.showLoader = false;
      }, 800);
    });
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  openMenu() {
    this._bottomSheet.open(this.menu);
  }

  async openNewCourseAdd() {
    this._bottomSheet.dismiss();
    const result = await firstValueFrom(
      this._bottomSheet.open(UserNewCoursedataDialogComponent, {
        data: {
          courses: this.courseList,
          userId: this.userID,
          personal: true
        }
      }).afterDismissed()
    );
    if (result) {
      this.loadData();
    }
  }

  items: Record[] = [];
  rawItems: Record[] = [];
}
