<h2 mat-dialog-title class="text-center">
  Buchung
  <span *ngIf="!timeId">erstellen</span>
  <span *ngIf="timeId">bearbeiten</span>
</h2>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="group" class="row">
    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Buchungsdatum</mat-label>
      <input matInput [matDatepicker]="picker" [max]="max" formControlName="date" placeholder="Datum" readonly />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error>Datum muss ausgefüllt sein!</mat-error>
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="outline">
      <mat-label>Kategorie auswählen</mat-label>
      <mat-select formControlName="category" id="courseDropdown">
        <mat-option>
          <ngx-mat-select-search [formControl]="categoryFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-optgroup *ngFor="let category of categoryFilter | async" [label]="category.name">
          <mat-option *ngFor="let data of category.data" [value]="data.id">
            {{ data.name }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
      <mat-error>Kategorie muss ausgefüllt sein!</mat-error>
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="outline" *ngIf="adminEdit === false">
      <mat-label>weitere Teilnehmer auswählen</mat-label>
      <mat-select formControlName="additionalUsers" id="additionalUsers" multiple="true"
        (selectionChange)="clearUserSearch()">
        <mat-option>
          <ngx-mat-select-search [formControl]="additionalUsersFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let additionalUser of additionalUsersFilter | async" [value]="additionalUser.id">{{
          additionalUser.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Kommen</mat-label>
        <input matInput mask="Hh:m0" #inField [dropSpecialCharacters]="false" inputmode="numeric"
          formControlName="in" />
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field class="input-full-width" appearance="outline">
        <mat-label>Gehen</mat-label>
        <input matInput mask="Hh:m0" #outField [dropSpecialCharacters]="false" inputmode="numeric"
          formControlName="out" />
      </mat-form-field>
    </div>

    <small class="text-danger" style="margin-top: -4px" *ngIf="group.get('in').hasError('smallerTime')">Kommen Zeit darf
      nicht größer Gehen Zeit
      sein!</small>
    <small class="text-danger" style="margin-top: -4px" *ngIf="group.get('in').hasError('sameTime')">Kommen Zeit darf
      nicht gleich Gehen Zeit
      sein!</small>
    <small class="text-danger" style="margin-top: -4px"
      *ngIf="group.get('in').hasError('pattern') || group.get('out').hasError('pattern')">Ungültiges
      Zeitformat (HH:mm)</small>

    <hr class="mt-2" />

    <mat-form-field class="input-full-width mt-4" appearance="outline">
      <mat-label>Kommentar</mat-label>
      <textarea matInput maxlength="100" formControlName="comment" cdkTextareaAutosize></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="clearfix w-100 pe-3">
    <button mat-raised-button class="float-end" color="accent" [disabled]="group.invalid" (click)="save()">
      <div class="clearfix submit-button">
        <mat-icon class="me-3 mt-1 float-left" [hidden]="!isLoading">
          <mat-spinner diameter="20" class="light"></mat-spinner>
        </mat-icon>
        <span class="me-1 float-left">Buchung speichern</span>
        <mat-icon class="float-right">save</mat-icon>
      </div>
    </button>
  </div>
</mat-dialog-actions>