import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import dayjs from 'dayjs';
import { Subscription, filter, firstValueFrom, skip } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { QrScannerComponent } from 'src/app/util/dialogs/qr-scanner/qr-scanner.component';
import { ActionAbortEvent, AppCommunicationService, BarcodeScannedEvent } from 'src/app/util/services/app-communication/app-communication.service';
import { NfcScannerDialogComponent } from 'src/app/util/dialogs/nfc-scanner-dialog/nfc-scanner-dialog.component';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-check-cycles',
  templateUrl: './check-cycles.component.html',
  styleUrls: ['./check-cycles.component.css']
})
export class CheckCyclesComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['device', 'materialCategory', 'name', 'date', 'action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  isLoading: boolean = true;
  filterCtrl = new FormControl('');


  dayjs = dayjs;
  subscription?: Subscription;

  env = environment;
  constructor(private _http: HttpClient,
    private _config: ConfigModule,
    public _bottomSheet: MatBottomSheet,
    private _router: Router,
    private _dialog: MatDialog,
    public _com: AppCommunicationService) { }

  ngOnInit(): void {
    this.loadData();

    this._config.setTitle('Anstehende Prüfungen');

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'check': return item.cycle ? dayjs(item.cycle).toDate() : null;
        default: return item[property];
      }
    };
  }

  ngAfterViewInit(): void {
    if (this.env.mobile) {
      this.displayedColumns = ['device', 'materialCategory', 'date', 'name', 'action'];
    }
  }

    openScanner() {
      if (this._com.isRunningInApp()) {
        this.subscription?.unsubscribe();
        this._com.sendAction('scanner');
        this.subscription = this._com.callback.pipe(
          skip(1)
        ).subscribe(async (result) => {
          if (result.result instanceof ActionAbortEvent) {
            this.subscription!.unsubscribe();
            return;
          }
          if (result.result instanceof BarcodeScannedEvent) {
            const value = result.result.data;
  
            if (value) {
              try {
                const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/search?q=${value}`));
                if (result.id) {
                  this._router.navigate(['material/equipment/details/', result.id]);
                } else {
                  throw new Error('Material nicht gefunden');
                }
              } catch (error) {
                this._config.showSnackbar('Es wurden keine Materialien gefunden!', 3000, 'error');
              }
            }
            this.subscription!.unsubscribe();
          }
        });
        return;
      }
  
      this._dialog.open(QrScannerComponent, {
        data: {
          title: 'Material ID Tag scannen'
        },
        width: '600px'
      }).afterClosed().subscribe(async (result) => {
        if (result.value) {
          if (result.value?.startsWith('DATA-')) {
            this._router.navigate(['material/equipment/details/', result.value.replace('QR-', '')]);
            this.filterCtrl.setValue(result.value);
          } else if (/^([^-\d]+-\d+)/.test(result.value)) {
            await this.findMaterial(result.value);
          } else {
            try {
              const value = JSON.parse(result.value);
              if (value) {
                try {
                  const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/search?q=${value}`));
                  if (result.id) {
                    this._router.navigate(['material/equipment/details/', result.id]);
                  } else {
                    throw new Error('Material nicht gefunden');
                  }
                } catch (error) {
                  this._config.showSnackbar('Es wurden keine Materialien gefunden!', 3000, 'error');
                }
              }
            } catch (e) {
              this._config.showSnackbar('Ungültiger Material Tag wurde gescannt, bitte versuche es erneut!', 3000, 'error');
            }
          }
        }
      });
    }
  
    async openNFCScanner() {
      const result = await firstValueFrom(this._dialog.open(NfcScannerDialogComponent, {
        width: '250px'
      }).afterClosed());
  
      if (result) {
        try {
          const httpResult = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/search?q=${result.text?.[0] ?? result?.uid ?? ''}`));
          if (httpResult.id) {
            this._router.navigate(['material/equipment/details/', httpResult.id]);
          } else {
            throw new Error('Material nicht gefunden');
          }
        } catch (error) {
          this._config.showSnackbar('Es wurden keine Materialien gefunden!', 3000, 'error');
        }
      }
    }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.toLowerCase();
  }

  async findMaterial(search: string) {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/search?q=${search}`));
      this.filterCtrl.setValue(result.id);
      this._router.navigate(['material/equipment/details/', result.id]);
    } catch (error) {
      this._config.showSnackbar('Es wurden keine Materialien gefunden!', 3000, 'error');
    }
  }

  async loadData() {
    try {
      let result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/checks/cycles`));
      result = result.map(check => {
        check.nextCycle = dayjs(check.date).diff(dayjs(), 'days') + 1;
        return check;
      });

      this.dataSource.data = result;
      
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    } catch (e) {
      this._config.showSnackbar('Fehler beim Laden der Prüfungen', 2500, 'error');
      this.isLoading = false;
    }
  }
}
