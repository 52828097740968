import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Inject } from '@angular/core';
import { ConfigModule, getHostUrl } from '../../../util/config';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../../util/login';
import { ActivatedRoute, Router } from '@angular/router';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Group, EventUser, UserName, response } from '@ffo/mitgliederbereich-types';
import { DateTime } from 'luxon';
import { firstValueFrom, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog as MatDialog, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import dayjs from 'dayjs';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css'],
})
export class EventDetailComponent implements OnInit, AfterViewInit {

  env = environment;
  today: Date;

  groups: Group[];
  event: EventUser;
  eventId: number = null;

  tabIndex: number = 0;
  attendance: UserName[] = [];
  demoted: boolean = false;

  constructor(private _config: ConfigModule,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    public _login: Login) {
    this._config.setTitle('Lädt...');
  }

  ngOnInit() {
    this.eventId = this._route.snapshot.params.eventId;
    this.today = new Date();
  }

  ngAfterViewInit(): void {
    this.getEvent();
    this.getAttendence();
    this.getUsersAttendence();
    this.filterdUsersResponse = this.addUserControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filterUsers(value, 'response')),
    );
    this.filterdUsersAttendence = this.addAttendenceControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filterUsers(value, 'attendence')),
    );
  }

  getEvent(): void {
    this._http.get<EventUser>(`${getHostUrl()}events?eventId=${this.eventId}`).subscribe(data => {
      if (!data.id) {
        this._config.showSnackbar('Veranstaltung nicht vorhanden', 2500, 'error');
        this._router.navigate(['/event']);
      } else {
        this.event = data;
        this._config.setTitle(this.event.title);
        if (this.event.response === 1) {
          document.getElementById('eventBorder')?.classList.remove('greenBorder');
          document.getElementById('eventBorder')?.classList.add('redBorder');
        } else if (this.event.response === 2) {
          document.getElementById('eventBorder')?.classList.remove('redBorder');
          document.getElementById('eventBorder')?.classList.add('greenBorder');
        } else {
          document.getElementById('eventBorder')?.classList.remove('greenBorder');
          document.getElementById('eventBorder')?.classList.remove('redBorder');
        }
        if (this.usersListResponse.length === 0 && (this.event.attendees.admin || this._login.hasPermission('event-response') || this._login.hasPermission('event-complete'))) {
          this.getUsersResponse();
        }
      }
    }, error => {
      this._config.showSnackbar(error.error.error, 2500, 'error');
      this._router.navigate(['/event']);
    });
    if (this.event && (this.event.attendees.admin || this._login.hasPermission('event-response') || this._login.hasPermission('event-complete'))) {
      this.getUsersResponse();
    }
  }

  openFeedback() {
    const result = Swal.fire({
      title: 'Rückmeldung abgeben',
      icon: 'question',
      showConfirmButton: true,
      showDenyButton: true,
      reverseButtons: true,
      confirmButtonText: 'Zusagen',
      denyButtonText: 'Absagen',
      returnInputValueOnDeny: true,
      input: 'textarea',
      inputLabel: 'Kommentar',
      inputValue: this.event.comment,
      inputValidator: (value: string) => {
        if (value.length > 200) {
          return 'max 200 Zeichen'
        }
      }
    });

    result.then((dialogResult: SweetAlertResult) => {
      if (dialogResult.isDismissed === false) {
        this.sendFeedback(dialogResult.isConfirmed ? 2 : 1, dialogResult.value || '');
      }
    });
  }

  async sendFeedback(response: number, comment: string, targetID?: number) {
    this.addUserControl.setValue('');
    try {
      await firstValueFrom(this._http.post(`${getHostUrl()}events/response`, {
        eventId: this.eventId,
        response,
        comment,
        targetID,
      }));
      this._config.showSnackbar('Rückmeldung wurde gespeichert.', 2000, 'success');
      this.getEvent();
    } catch (error) {
      this._config.showSnackbar('Rückmeldung wurde nicht gespeichert.', 2000, 'error');
    }
  }

  checkLink(name: string) {
    if (name !== 'Ausbildereintragung') {
      return true;
    } else {
      if (this._login.hasPermission('mta-link-view'))
        return true;
    }
    return false;
  }

  getAttendees(): number {
    if (this.event.attendees.accepted instanceof Array) {
      return this.event.attendees.accepted.length;
    } else {
      return this.event.attendees.accepted;
    }
  }

  getRejected(): number {
    if (this.event.attendees.rejected instanceof Array) {
      return this.event.attendees.rejected.length;
    } else {
      return this.event.attendees.rejected;
    }
  }

  checkResponseTime(): boolean {
    if (!this.event) {
      return false;
    }
    if (this.event.limitResponse.active === false) {
      const start: Date = this.event.allDay ? DateTime.fromFormat(this.event.start, 'dd.MM.yyyy').toJSDate() : DateTime.fromFormat(this.event.start, 'dd.MM.yyyy HH:mm').toJSDate();
      const end: Date = this.event.allDay ? DateTime.fromFormat(this.event.end, 'dd.MM.yyyy').toJSDate() : DateTime.fromFormat(this.event.end, 'dd.MM.yyyy HH:mm').toJSDate();
      const startTime: string = this.event.allDay ? '00:00' : this.event.start.split(' ')[1];
      const endTime: string = this.event.allDay ? '00:00' : this.event.end.split(' ')[1];
      return this.calcResponseTime(start, end, startTime, endTime, false);
    } else {
      return this.calcResponseTime(new Date(this.event.limitResponse.start.date), new Date(this.event.limitResponse.end.date), this.event.limitResponse.start.time, this.event.limitResponse.end.time);
    }
  }

  private calcResponseTime(_start: Date, _end: Date, _startTime: string, _endTime: string, checkStart: boolean = true): boolean {
    const now = DateTime.now();
    const startDate = DateTime.fromJSDate(_start).setZone('Europe/Berlin');
    const endDate = DateTime.fromJSDate(_end).setZone('Europe/Berlin');
    const startTime = DateTime.fromSQL(_startTime).setZone('Europe/Berlin');
    const endTime = DateTime.fromSQL(_endTime).setZone('Europe/Berlin');
    const start = startDate.set({ hour: startTime.hour, minute: startTime.minute });
    let end = endDate.set({ hour: endTime.hour, minute: endTime.minute });
    if (_endTime === '00:00') {
      end = end.minus({ days: 1 });
    }
    if (start <= now && now <= end && checkStart) {
      return true;
    } else if (now <= end && !checkStart) {
      return true;
    }
    return false;
  }

  checkMeetingControls(preCount: number = 4): boolean {
    const now = DateTime.now();
    const startDate: DateTime = this.event.allDay ? DateTime.fromFormat(this.event.start, 'dd.MM.yyyy') : DateTime.fromFormat(this.event.start, 'dd.MM.yyyy HH:mm');
    const startTime: DateTime = DateTime.fromSQL(this.event.allDay ? '00:00' : this.event.start.split(' ')[1]);
    const start: DateTime = startDate.set({ hour: startTime.hour, minute: startTime.minute }).minus({ hours: preCount });
    if (start <= now) {
      return true;
    }
    return false;
  }

  addUserComment: string = '';
  addUserControl: UntypedFormControl = new UntypedFormControl('');
  usersListResponse: UserName[] = [];
  addAttendenceControl: UntypedFormControl = new UntypedFormControl('');
  usersListAttendence: UserName[] = [];
  filterdUsersResponse: Observable<UserName[]>;
  filterdUsersAttendence: Observable<UserName[]>;
  @ViewChild('addPersonDialog') addPersonDialog: TemplateRef<any>;
  @ViewChild('addAttendeeDialog') addAttendeeDialog: TemplateRef<any>;

  addPerson(): void {
    this._dialog.open(this.addPersonDialog);
  }

  getUsersResponse(): void {
    this._http.get<UserName[]>(`${getHostUrl()}events/users?eventId=${this.eventId}`).subscribe(data => {
      this.usersListResponse = data;
    });
  }

  private _filterUsers(value: string, type: 'attendence' | 'response'): UserName[] {
    if (typeof (value) === 'string') {
      const filterValue = value.toLowerCase();
      if (type === 'response')
        return this.usersListResponse.filter((user: UserName) => { return user.userID.toString().startsWith(filterValue) || user.firstname.toLowerCase().startsWith(filterValue) || user.lastname.toLowerCase().startsWith(filterValue); });
      else
        return this.usersListAttendence.filter((user: UserName) => { return user.userID.toString().startsWith(filterValue) || user.firstname.toLowerCase().startsWith(filterValue) || user.lastname.toLowerCase().startsWith(filterValue); });
    }
    return [];
  }

  displayFn(user: UserName): string {
    return user && user.firstname && user.lastname && user.userID ? (`${user.userID} - ${user.firstname} ${user.lastname}`) : '';
  }

  addResponseToAttendance(): void {
    this._http.post(`${getHostUrl()}events/attendance`,
      {
        eventId: this.eventId,
        attendees: [...new Set((this.event.attendees.accepted as response[]).map(u => u.userID).concat(this.attendance.map(u => u.userID)))]
      }).subscribe({
        next: () => {
          this.getAttendence();
          this.getUsersAttendence();
          this.addAttendenceControl.setValue('');
          this._config.showSnackbar('Anwesenheit übernommen', 2500, 'success');
          this.tabIndex++;
        },
        error: (err: any) => {
          this._config.showSnackbar('Fehler beim Speichern', 2500, 'error');
        }
      });
  }

  getAttendence() {
    this._http.get<UserName[]>(`${getHostUrl()}events/attendance?eventId=${this.eventId}`).subscribe(
      (data: UserName[]) => {
        this.attendance = data;
        this.addAttendenceControl.setValue('');
      },
    );
  }

  getUsersAttendence(): void {
    this._http.get<UserName[]>(`${getHostUrl()}events/attendees?eventId=${this.eventId}`).subscribe(data => {
      this.usersListAttendence = data;
      this.addAttendenceControl.setValue('');
    });
  }

  openAttendance(): void {
    const dialog = this._dialog.open(this.addAttendeeDialog);
    dialog.afterClosed().subscribe(() => {
      if (this.demoted)
        this.demoted = false;
    })
  }

  saveAttendee(userID: number): void {
    this._http.post(`${getHostUrl()}events/attendance`, { eventId: this.eventId, userID }).subscribe({
      next: () => {
        this._config.showSnackbar('Anwesenheit gepflegt', 2500, 'success');
        this.getAttendence();
        this.getUsersAttendence();
      },
      error: () => {
        this._config.showSnackbar('Fehler beim Speichern', 2500, 'error');
      }
    });
  }

  removeAttendee(userID: number): void {
    this._http.delete(`${getHostUrl()}events/attendance`, { body: { eventId: this.eventId, userID } }).subscribe({
      next: () => {
        this._config.showSnackbar('Anwesenheit gepflegt', 2500, 'success');
        this.getAttendence();
        this.getUsersAttendence();
        this.demoted = false;
      },
      error: () => {
        this._config.showSnackbar('Fehler beim Löschen', 2500, 'error');
      }
    });
  }

  openFinal(): void {
    const dialog = this._dialog.open(EventFinalComponent, { data: this.event });
    dialog.afterClosed().subscribe(() => {
      this.getEvent();
    })
  }
}

@Component({
  selector: 'app-event-detail-final',
  templateUrl: './final.html',
  styleUrls: ['./event-detail.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class EventFinalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) protected _data: EventUser, private _http: HttpClient, private _config: ConfigModule, private _dialog: MatDialog) { }

  timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  group: UntypedFormGroup;
  startDate: Date;
  startTime: string;
  today: Date = new Date();

  ngOnInit(): void {
    const endDate: number[] = this._data.end.split(' ')[0].split('.').map(x => parseInt(x));
    const tstartDate: number[] = this._data.start.split(' ')[0].split('.').map(x => parseInt(x));
    this.startTime = this._data.start.split(' ')[1];
    this.startDate = new Date(tstartDate[2], tstartDate[1] - 1, tstartDate[0]);
    this.group = new UntypedFormGroup({
      endTime: new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.timePattern)])),
      description: new FormControl(this._data.description || '', Validators.required),
      endDate: new FormControl(new Date(endDate[2], endDate[1] - 1, endDate[0]), Validators.required),
      startDate: new FormControl(this.startDate),
      startTime: new FormControl(this.startTime)
    }, { validators: validateEndTime });
  }

  parseTimeField($event) {
    if ($event.keyCode === 8 || $event.keyCode === 46 || $event.keyCode === 9 || $event.keyCode === 16) {
      return;
    }
    const value: string = this.group.get('endTime').value;

    if (value.match(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#´]/)) {
      this.group.get('endTime').setValue(value.replace(/[a-z]|[A-Z]|[ÄÖÜäöüß]|[-!$%^&*()_+|~=`{}\[\]";'<>?,.\/§#]/g, ''));
    }

    if (value.length > 5) {
      this.group.get('endTime').setValue(value.substring(0, 5));
      return;
    }
    if (value.match(this.timePattern)) {
      $('#description').trigger('focus');
      return;
    }
    if (!value.includes(':')) {
      if (value.length === 2) {
        this.group.get('endTime').setValue(value + ':');
      }
    }
    return true;
  }

  save(): void {
    const date = this.group.get('endDate').value as Date;
    const group = {
      endTime: this.group.get('endTime').value,
      description: this.group.get('description').value,
      endDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    };
    this._http.post(`${getHostUrl()}events/complete`, { eventId: this._data.id, group }).subscribe({
      next: () => {
        this._config.showSnackbar('Event abgeschlossen', 2500, 'success');
        this._dialog.closeAll();
      },
      error: () => {
        this._config.showSnackbar('Fehler beim Speichern', 2500, 'error');
      }
    });
  }
}

function validateEndTime(c: AbstractControl): ValidationErrors {
  if (dayjs(c.get('endDate').value).isAfter(dayjs(c.get('startDate').value))) {
    return null;
  }
  const startTime = dayjs().set('hour', c.get('startTime').value.split(':')[0]).set('minute', c.get('startTime').value.split(':')[1]);
  const endTime = dayjs().set('hour', c.get('endTime').value.split(':')[0]).set('minute', c.get('endTime').value.split(':')[1]);

  if (endTime.isBefore(startTime)) {
    c.get('endTime').setErrors({ smallerTime: true });
    return {
      invalidDate: true,
    };
  }
  return null;
}