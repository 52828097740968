import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { ConfigModule } from 'src/app/util/config';
import { CustomDateAdapterService } from 'src/app/util/customDateAdapter';
import { CreateTimeTrackingDialogComponent } from 'src/app/util/dialogs/create-time-tracking-dialog/create-time-tracking-dialog.component';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-time-tracking-day-list',
  templateUrl: './time-tracking-day-list.component.html',
  styleUrls: ['./time-tracking-day-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapterService,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: { display: { dateInput: 'dateInput' } } },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
  ]
})
export class TimeTrackingDayListComponent implements OnInit {
  constructor(private _http: HttpClient,
    private _dialog: MatDialog,
    private _config: ConfigModule
  ) { }

  ngOnInit(): void {
    this.loadTimes();
    this.currentDate.valueChanges.subscribe(() => {
      this.loadTimes();
    });
  }

  max = new Date();

  @Input() currentDate: FormControl;
  dataSource = new MatTableDataSource<any>();
  showLoader = true;
  totalTime = '00:00:00';
  startTime = '00:00:00';
  endTime = '00:00:00';

  displayedColumns = ['category', 'comment', 'timeframe', 'total', 'status', 'action']
  dayjs = dayjs;


  previousDate() {
    const dt = dayjs(this.currentDate.value).subtract(1, 'd');
    this.currentDate.setValue(dt.toDate());
  }

  nextDate() {
    const dt = dayjs(this.currentDate.value).add(1, 'd');
    if (dt.isAfter(this.max, 'd')) return;
    this.currentDate.setValue(dt.toDate());
  }

  async loadTimes() {
    this.showLoader = true;
    try {
      const result = await firstValueFrom(this._http.get<any>(`api/time?date=${dayjs
        (this.currentDate.value).format('YYYY-MM-DD')}`));
      const data = result.data;
      data.push({

        total: result.total
      });
      this.dataSource.data = data;
      this.totalTime = result.total;
      this.startTime = result.start;
      this.endTime = result.end;

    } catch (error) {
      if (error?.error?.error) {
        this._config.showSnackbar(error.error.error, 3000, 'error');
        return;
      }
      this._config.showSnackbar('Konnte Buchungen nicht abrufen!', 3000, 'error');
    } finally {
      this.showLoader = false;
    }
  }

  async editEntry(item: any) {
    if (item.verified !== 0)
      return;
    const result = await firstValueFrom(this._dialog.open(CreateTimeTrackingDialogComponent, {
      width: '500px',
      data: {
        date: this.currentDate.value,
        item
      }
    }).afterClosed());

    if (result) {
      this.loadTimes();
    }
  }

  async deleteEntry(id: number) {
    const popup = await Swal.fire({
      title: 'Löschen?',
      text: 'Soll diese Buchung wirklich gelöscht werden?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Ja, löschen',
      denyButtonText: 'Abbrechen',
      reverseButtons: true,
      icon: 'question'
    });
    if (!popup.isConfirmed) {
      return;
    }

    this.showLoader = true;

    try {
      await firstValueFrom(this._http.delete(`api/time/${id}`));
      this.loadTimes();
      this._config.showSnackbar('Buchung wurde erfolgreich gelöscht!', 3000, 'success');
    } catch (error) {
      if (error?.error?.error) {
        this._config.showSnackbar(error.error.error, 3000, 'error');
        return;
      }
      this._config.showSnackbar('Fehler beim Löschen der Buchung!', 3000, 'error');
    } finally {
      this.showLoader = false;
    }
  }
}
